import { atom } from 'recoil';
import Cookie from 'js-cookie';

const user = Cookie.get('user');
export const authAtom = atom({
  key: 'authAtom',
  default: Cookie.get('accessToken'),
});
export const userAtom = atom({
  key: 'userAtom',
  default: user ? JSON.parse(user) : null,
});

export const isSellerAtom = atom({
  key: 'isSellerAtom',
  default: false,
});
