import React from 'react';
import bg from '../../assets/bg/signup-bg.png';
import { Link } from 'react-router-dom';
import Logo from '../../components/Logo';
import { useSetRecoilState } from 'recoil';
import { isSellerAtom } from '../../recoil/atom';
import { Box, Button, CardMedia, Grid, Stack, Typography } from '@mui/material';
import LeoLogo from '../../assets/bg/Leo-logo.png';
import login_logo from '../../assets/bg/login_logo.png';

const NoStore = () => {
  const setIsSeller = useSetRecoilState(isSellerAtom);
  return (
    <Grid container sx={{ p: 2 }}>
      <Grid item xs={12} ml={{ xs: 0, sm: 5 }}>
        <Stack direction={"column"}>
          <CardMedia
            component="img"
            sx={{
              height: 50,
              width: 60,
              maxHeight: { xs: 233, sm: 450, },
              maxWidth: { xs: 350, md: "60%" },
            }}
            src={LeoLogo}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}
            component={Box}
            display={{ xs: "none", sm: "block" }}
          >
            <Stack justifyContent="center" alignItems="center" direction="column">
              <CardMedia
                component="img"
                sx={{
                  height: "80vh",
                  width: "70%",
                  maxHeight: { xs: 233, sm: 400, },
                  maxWidth: { xs: 350, md: "70%" },
                }}
                src={login_logo}
              />

            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            {/* <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form> */}
            <Grid container spacing={2} sx={{ py: 2, px: 1 }}>
              <Grid item xs={12} lg={7}>
                <Stack direction={"column"}>
                  <Typography
                    variant="span"
                    sx={{ fontWeight: "bolder", fontSize: "36px", mt: 2 }}
                  >
                    Let’s setup your Store
                  </Typography>

                  <Typography
                    variant="span"
                    sx={{ fontSize: "14px", mt: 2, color: "#9DA8B6" }}
                  >
                    <Typography
                      variant="span"
                      sx={{ fontSize: "14px", mt: 2, color: "#FC500D" }}
                    >Note :
                    </Typography>    A verifcation code/link has been sent to your Email
                    Please click on the link to verify your account.
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12} lg={7} mt={{ xs: 15, sm: 2 }}>
                <Button
                  type="submit"
                  sx={{
                    color: "#fff",
                    background: "#FC500D !important",
                    width: "100%",
                    textTransform: "capitalize",
                  }}
                >
                  Setup store now

                </Button>
              </Grid>

              <Grid item xs={12} sm={12} lg={7} mt={{ xs: 3, sm: 0 }}>
                <Button
                  type="submit"
                  sx={{
                    color: "#FC500D",
                    border: "1px solid #FC500D",
                    width: "100%",
                    textTransform: "capitalize",
                  }}
                >
                  Skip, verify later
                </Button>
              </Grid>
            </Grid>
            {/* </Form>
              )}
            </Formik> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    // <div className='signup-container'>
    //   <div className='form-container'>
    //     <div className='main-form'>
    //       <Logo />
    //       <h3 className='mb-4 mt-3'>
    //         <b>Sign up.</b>
    //       </h3>

    //       <div className='type'>
    //         <p className='text-gray mt-4'>How would you like to sign up?</p>
    //         <Link to='/register'>
    //           <button className=' btn-primary mt-3 w-100 l-3 btn-border-radius'>
    //             Sign up as a buyer
    //           </button>
    //         </Link>
    //         <Link to='/register'>
    //           <button
    //             onClick={() => setIsSeller(true)}
    //             className='btn-border-primary btn-border-radius  mt-3 w-100 l-3'
    //           >
    //             Sign up as a seller
    //           </button>
    //         </Link>

    //         <Link to='/'>
    //           <p className='text-gray text-center mt-4'>
    //             Already have an account? Sign In
    //           </p>
    //         </Link>
    //       </div>
    //     </div>
    //   </div>
    //   <div className='form-bg'>
    //     <img src={bg} alt='Login background' />
    //   </div>
    // </div>
  );
};

export default NoStore;
