export const userSrvProdBaseUrl = process.env.REACT_APP_USER_API_URL;
export const notificationSrvProdBaseUrl =
  process.env.REACT_APP_NOTIFICATION_API_URL;
export const accessTokenKey = process.env.REACT_APP_ACCESS_TOKEN;
export const refreshTokenKey = process.env.REACT_APP_REFRESH_TOKEN;
export const ROOT_DOMAIN = process.env.REACT_APP_ROOT_DOMAIN;
export const userCacheKey = process.env.REACT_APP_USER;
export const inventoryPortalUrl = process.env.REACT_APP_INVENTORY_URL;

//Gateway URL
export const gateWayUrl = process.env.REACT_APP_API_GATEWAY;
export const userBasePath = process.env.REACT_APP_USER_BASE_PATH;
export const STORE_URL = process.env.REACT_APP_STORE_URL;

export const notificationBasePath = process.env.REACT_APP_NOTIFICATION_BASE_PATH;

export const GRAPHQL = process.env.REACT_APP_USE_SERVICE_GRAPHQL_PATH

export const FEDERATIONGRAPHQL = process.env.REACT_APP_FEDERATION_GRAPHQL_PATH



export const BASE_URL = {
  USER_SRV: gateWayUrl,
  NOTIFICATION_SRV: gateWayUrl,
};

export const BASE_PATH = {
  USER_SRV: userBasePath,
  NOTIFICATION_SRV: notificationBasePath,
};
