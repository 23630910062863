import React from 'react';
import logo from '../assets/logo.png';

const Logo = () => {
  return (
    <div>
        <img src={logo} alt='' />
    </div>
  );
};

export default Logo;
