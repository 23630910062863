import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './styles/global.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/hoc/ErrorBoundary';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GRAPHQL, FEDERATIONGRAPHQL } from './config/envVars';
import Cookies from 'js-cookie';

// const location = navigator.geolocation.getCurrentPosition();
// console.log('location', location);

const httpLink = createHttpLink({
  uri: FEDERATIONGRAPHQL,
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Cookies.get('accessToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      Accept: "application/json",
      'Content-Type': "application/json",
      lat: "6.845014",
      long: "7.374712",
      authorization: token ? `${token}` : "",

    },

  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  // headers: {

  //   Accept: "application/json",
  //   'Content-Type': "application/json",
  //   lat: "6.845014",
  //   long: "7.374712"
  // }
});
ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <RecoilRoot>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>,
      </RecoilRoot>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
