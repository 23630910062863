import React, { Suspense } from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import axios from 'axios';
// import TestForm from '../components/TestForm';
import NoStore from './SignUp/NoStore';
import { ToastContainer } from 'react-toastify';
import '../styles/main.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'sweetalert2/src/sweetalert2.scss';
import { authAtom } from '../recoil/atom';

// LazyLoading
const LazyRegister = React.lazy(() => import('./SignUp/Register'));
const LazyLogin = React.lazy(() => import('./Login'));
const LazyForgotPassword = React.lazy(() =>
  import('./Forgotpassword/ForgotPassword')
);
const LazyResetPassword = React.lazy(() =>
  import('./Forgotpassword/ResetPassword')
);

const LazyVerification = React.lazy(() => import('./Verification'));

const LazyChangePassword = React.lazy(() => import('./Profile/ChangePassword'));
const LazySubscription = React.lazy(() => import('./Profile/Subscription'));
const LazyLocationDetails = React.lazy(() =>
  import('./Profile/LocationDetails')
);
const LazyPaymentDetails = React.lazy(() => import('./Profile/PaymentDetails'));
const LazyPersonalInfo = React.lazy(() => import('./Profile/PersonalInfo'));
const LazyProfileImage = React.lazy(() => import('./Profile/UploadImage'));
const LazySecuritySetup = React.lazy(() => import('./Profile/SecuritySetup'));
const LazyDeviceManagement = React.lazy(() =>
  import('./Profile/DeviceManagement')
);
const LazyAccountActivity = React.lazy(() =>
  import('./Profile/AcountActivity')
);
console.log('THIS IS ME USER_PORTAL');
function App() {
  const [auth] = useRecoilState(authAtom);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnReconnect: true,
      },
    },
  });

  async function handleRequest(req) {
    if (auth) {
      req.headers.Authorization = `Bearer ${auth}`;
      //    logger.info('AUTH HEADER', req.headers.Authorization);
    }
    req.headers.Accept = 'application/json';
    req.headers.timestamp = new Date().getTime();
    return req;
  }

  // async function handleResponse(res) {
  //   logger.info('API RESPONSES', res.data);
  //   if (res.status === 401 || res?.data?.code === 401) {
  //     //  Refresh
  //     const res = await refreshAuthToken();
  //     // Set Auth
  //     localStorage.setItem(accessTokenKey, res.data.accessToken);
  //     setAuth(res.data.accessToken);
  //     //  logger.info('New access token', res.data.accessToken);
  //   }
  //   return res;
  // }

  // async function handleError(error) {
  //   if (error.code === 401) {
  //     logger.info('401 Error', error);
  //     //  Refresh
  //     setAuth(null);
  //     const res = await refreshAuthToken();
  //     logger.info('REFRESH TOKEN', res.data.accessToken);
  //     localStorage.setItem(accessTokenKey, res.data.accessToken);
  //     setAuth(res.data.accessToken);

  //     error.headers.Authorization = `Bearer ${res.data.accessToken}`;

  //     logger.info('ERROR CONFIG', error.config);
  //     return axios.request(error.config);
  //     //   logger.info('New access token', res.data.accessToken);
  //   }

  //   logger.info('Error Response', error);

  //   throw error;

  //   // throw (
  //   //   error.response.data ?? { msg: error.message ?? 'Something went wrong' }
  //   // );
  // }

  /**
   * This is used to modify the header request and relies on some header constraints
   * to generate some header fields
   */
  axios.interceptors.request.use(
    async (req) => await handleRequest(req)
    // async (error) => await handleError(error)
  );

  /**
   * This is used to modify response call to reprocess error 401 and generate new
   * token to use for new and current running request.
   */
  // axios.interceptors.response.use(
  //   async (res) => await handleResponse(res)
  //   // handleError
  // );

  return (
    <Suspense fallback={() => <h4>Loading...</h4>}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Switch>
            <Route exact path='/' component={LazyLogin} />
            <Route exact path='/no-store' component={NoStore} />
            <Route exact path='/register' component={LazyRegister} />
            <Route exact path='/verify-email' component={LazyVerification} />
            <Route
              exact
              path='/forgot-password'
              component={LazyForgotPassword}
            />
            <Route path='/reset-password' component={LazyResetPassword} />
            <Route exact path='/user-profile' component={LazyPersonalInfo} />
            <Route
              exact
              path='/user-profile/profile-pic'
              component={LazyProfileImage}
            />
            <Route
              exact
              path='/user-profile/security-setup'
              component={LazySecuritySetup}
            />
            <Route
              exact
              path='/user-profile/security-setup/device-management'
              component={LazyDeviceManagement}
            />
            <Route
              exact
              path='/user-profile/security-setup/account-activity'
              component={LazyAccountActivity}
            />
            <Route
              exact
              path='/user-profile/change-password'
              component={LazyChangePassword}
            />
            <Route
              exact
              path='/user-profile/subscription'
              component={LazySubscription}
            />
            <Route
              exact
              path='/user-profile/location-details'
              component={LazyLocationDetails}
            />
            <Route
              exact
              path='/user-profile/payment-details'
              component={LazyPaymentDetails}
            />
          </Switch>
        </Router>
        <ToastContainer
          position='bottom-right'
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
        />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Suspense>
  );
}

export default App;
